
import axios from "axios";
import iziToast from 'izitoast';
import { ADD_APPLICATION, ALL_APPLICATION, ALL_CLOSE_APPLICATION, DELETE_APPLICATION, MALIKANA_PORIBORTON, MALIKANA_PORIBORTON_LEASE, SEARCH_APPLICATION, UPDATE_APPLICATION, UPDATE_CLOSE_MARKET_APPLICATION, UPDATE_MARKET_APPLICATION } from "./action.type";
import { AFTER_ADD_APPLICATION, AFTER_DELETE_APPLICATION, AFTER_MALIKANA_PORIBORTON, AFTER_MALIKANA_PORIBORTON_LEASE, AFTER_UPDATE_APPLICATION, SET_APPLICATION } from "./mutation.type";


const state = {
    applications: {}
};

const getters = {
    allApplication(state){
        return state.applications
    }
}

const actions = {
    [ALL_APPLICATION](context,data) {
        return new Promise((resolve, reject) => {
            axios
            .get("/api/application?page="+data.page+"&type="+data.type)
            .then(response => {
                context.commit(SET_APPLICATION, response.data);
                resolve(response);
            })
            .catch(function(error) {
                reject(error);
            });
        });
    },
    [ALL_CLOSE_APPLICATION](context,data) {
        return new Promise((resolve, reject) => {
            axios
            .get("/api/close-application?page="+data.page+"&type="+data.type)
            .then(response => {
                context.commit(SET_APPLICATION, response.data);
                resolve(response);
            })
            .catch(function(error) {
                reject(error);
            });
        });
    },
    [DELETE_APPLICATION]({commit},id_index) {
        return new Promise((resolve, reject) => {
            axios
            .delete("/api/application/"+id_index.id)
            .then(response => {
                commit(AFTER_DELETE_APPLICATION,id_index.index);
                resolve(response);
            })
            .catch(function(error) {
                reject(error);
            });
        });
    },
    [ADD_APPLICATION]({commit},data) {
        return new Promise((resolve, reject) => {
            axios
            .post("/api/application",data)
            .then(response => {
                commit(AFTER_ADD_APPLICATION,response.data);
                resolve(response);
            })
            .catch(function(error) {
                state.add_APPLICATION_loader = false

                reject(error);
            });
        });
    },
    [UPDATE_APPLICATION]({commit},data) {
        return new Promise((resolve, reject) => {
            axios
            .put("/api/application",data.data)
            .then(response => {
                var update_data = response.data
                var index = data.index
                commit("AFTER_UPDATE_APPLICATION",{update_data,index});
                resolve(response);
            })
            .catch(function(error) {
                state.update_APPLICATION_loader = false
                reject(error);
            });
        });
    },
    [UPDATE_MARKET_APPLICATION]({commit},data) {
        return new Promise((resolve, reject) => {
            axios
            .post("/api/application-update",data.data)
            .then(response => {
                var update_data = response.data
                var index = data.index
                commit("AFTER_UPDATE_APPLICATION",{update_data,index});
                resolve(response);
            })
            .catch(function(error) {
                state.update_APPLICATION_loader = false
                reject(error);
            });
        });
    },
    [UPDATE_CLOSE_MARKET_APPLICATION]({commit},data) {
        return new Promise((resolve, reject) => {
            axios
            .post("/api/close-application-update",data.data)
            .then(response => {
                var update_data = response.data
                var index = data.index
                commit("AFTER_UPDATE_APPLICATION",{update_data,index});
                resolve(response);
            })
            .catch(function(error) {
                state.update_APPLICATION_loader = false
                reject(error);
            });
        });
    },
    [MALIKANA_PORIBORTON]({commit},data) {
        return new Promise((resolve, reject) => {
            axios
            .post("/api/malikana-poriborton",data.data)
            .then(response => {
                var update_data = response.data
                var index = data.index
                commit("AFTER_MALIKANA_PORIBORTON",{update_data,index});
                resolve(response);
            })
            .catch(function(error) {
                state.update_APPLICATION_loader = false
                reject(error);
            });
        });
    },
    [MALIKANA_PORIBORTON_LEASE]({commit},data) {
        return new Promise((resolve, reject) => {
            axios
            .post("/api/malikana-poriborton-lease",data.data)
            .then(response => {
                var update_data = response.data
                var index = data.index
                commit("AFTER_MALIKANA_PORIBORTON_LEASE",{update_data,index});
                resolve(response);
            })
            .catch(function(error) {
                state.update_APPLICATION_loader = false
                reject(error);
            });
        });
    },
    [SEARCH_APPLICATION](context,data) {
        return new Promise((resolve, reject) => {
            axios
            .post('api/application/pagination?page=' + data.page,data.data)
            .then(response => {
                context.commit(SET_APPLICATION, response.data);
                resolve(response);
            })
            .catch(function(error) {
                reject(error);
            });
        });
    },
}

const mutations = {
    [SET_APPLICATION](state, data)
    {
        state.applications = data
    },
    [AFTER_DELETE_APPLICATION](state,index){
        state.applications.data.splice(index,1)
    },
    [AFTER_ADD_APPLICATION](state,data){
        // if(state.applications.data==undefined) state.applications.data = []
        state.applications.data.unshift(data)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"আবেদন করতে সফল হয়েছেন"})
    },
    [AFTER_UPDATE_APPLICATION](state,payload){
        state.applications.data.splice(payload.index,0,payload.update_data)
        state.applications.data.splice(payload.index+1,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Application Updated Successsfully"})
    },
    [AFTER_MALIKANA_PORIBORTON](state,payload){
        state.applications.data.splice(payload.index,0,payload.update_data)
        state.applications.data.splice(payload.index+1,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Ownership Changed Successsfully"})
    },
    [AFTER_MALIKANA_PORIBORTON_LEASE](state,payload){
        state.applications.data.splice(payload.index,0,payload.update_data)
        state.applications.data.splice(payload.index+1,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Ownership Changed Successsfully"})
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};