import axios from 'axios'
import iziToast from "izitoast";

const state={
    wards:[],
    holdings:{},
    roadAddresses: [],
    custmerLists: [],
    modal:false
}

const getters ={
    wards(state){
        return state.wards
    },
    holdings(state){
        return state.holdings
    },
    roadAddresses(state){
        return state.roadAddresses
    },
    custmerLists(state){
        return state.custmerLists
    },
    modal(state){
        return state.modal
    }
}

const mutations ={
    ['SET_WARDS'](state,data){
        state.wards = data
    },
    ['SET_HOLDINGS'](state,data){
        state.holdings = data
    },
    ['SET_DELETE_HOLDING'](state,index){
       state.holdings.data.splice(index,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Holding Delated Successsfully"})
    },
    ['SET_ROAD_ADDRESS'](state,data){
        state.roadAddresses = data
    },
    ['SET_DELETE_ROAD_ADDRESS'](state,index){
        state.roadAddresses.splice(index,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Road Address Delated Successsfully"})
    },
    ['SET_CUSTOMER_TYPE'](state,data){
        state.custmerLists = data
    },
    ['SET_CUSTOMER_TYPE_DELETE'](state,index){
        state.custmerLists.splice(index,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Customer Type Delated Successsfully"})
    },
    ['AFTER_ADD_HOLDER'](state,data){
        state.holdings.data.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Holding Added Successsfully"})
    },
    ['AFTER_ADD_ROAD_ADDRESS'](state,data){
        state.roadAddresses.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Road Address Added Successsfully"})
    },
    ['AFTER_ADD_CUSTOMER_TYPE'](state,data){
        state.custmerLists.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Customer Type Added Successsfully"})
    },
    ['AFTER_UPDATE_HOLDER'](state,payload){
        state.holdings.data.splice(payload.index,0,payload.update_data)
        state.holdings.data.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Holding Updated Successsfully"})
    },
    ['AFTER_UPDATE_ROAD_ADDRESS'](state,payload){
        state.roadAddresses.splice(payload.index,0,payload.update_data)
        state.roadAddresses.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Road Address Updated Successsfully"})
    },
    ['AFTER_UPDATE_CUSTOMER_TYPE'](state,payload){
        state.custmerLists.splice(payload.index,0,payload.update_data)
        state.custmerLists.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Customer Type Updated Successsfully"})
    }
}

const actions ={
    ['WARD_LIST']({commit},data){
        axios.get('/ward/lists?invoice_type='+data.invoice_type)
            .then(response=>{
                commit('SET_WARDS',response.data)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['ADD_HOLDING']({commit},data){
        return new Promise((resolve,reject)=>{
            axios.post('/holding',data)
                .then(response=>{
                    commit('AFTER_ADD_HOLDER',response.data)
                    resolve(response.data)
                })
                .catch(errors=>{
                    console.log(errors)
                    reject(errors)
                })
        })

    },
    ['UPDATE_HOLDING']({commit},data){
        return new Promise((resolve,reject)=>{
            axios.put(`/holding/edit/${data.editForm.id}`,data.editForm)
                .then(response=>{
                    var update_data = response.data
                    var index = data.index
                    commit('AFTER_UPDATE_HOLDER',{update_data,index})
                    resolve(response.data)
                })
                .catch(errors=>{
                    console.log(errors)
                    reject(errors)
                })
        })

    },
    ['DELETE_HOLDING']({commit},id){
        axios.delete(`/holding/${id.id}`)
            .then(response=>{
                commit('SET_DELETE_HOLDING',id.index)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['HOLDING_LIST']({commit},data){
        axios.post('/holding/list?page='+data.page,data.data_1)
            .then(response=>{
                console.log(response.data)
                commit('SET_HOLDINGS',response.data)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['ROAD_ADDRESS_LIST']({commit}){
        axios.get('/road-address/lists')
            .then(response=>{
                console.log(response.data)
                commit('SET_ROAD_ADDRESS',response.data)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['ADD_ROAD_ADDRESS']({commit},data){
        axios.post('/road-address',data)
            .then(response=>{
                commit('AFTER_ADD_ROAD_ADDRESS',response.data)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['UPDATE_ROAD_ADDRESS']({commit},data){
        axios.put(`/road-address/edit/${data.editForm.id}`,data.editForm)
            .then(response=>{
                var update_data = response.data
                var index = data.index
                commit('AFTER_UPDATE_ROAD_ADDRESS',{update_data,index})
            })
            .catch(errors=>{
                console.log(errors)
            })
    },
    ['DELETE_ROAD_ADDRESS']({commit},id){
    axios.delete(`/road-address/${id.id}`)
        .then(response=>{
            commit('SET_DELETE_ROAD_ADDRESS',id.index)
        })
        .catch(errors=>{
            console.log(errors)
        })

    },
    ['CUSTOMER_TYPE_LIST']({commit}){
        axios.get('/customer/lists')
            .then(response=>{
                console.log(response.data)
                commit('SET_CUSTOMER_TYPE',response.data)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['ADD_CUSTOMER_TYPE']({commit},data){
        axios.post('/customer',data)
            .then(response=>{
                commit('AFTER_ADD_CUSTOMER_TYPE',response.data)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
    ['UPDATE_CUSTOMER_TYPE']({commit},data){
        axios.put(`/customer/edit/${data.customerEditForm.id}`,data.customerEditForm)
            .then(response=>{
                var update_data = response.data
                var index = data.index
                commit('AFTER_UPDATE_CUSTOMER_TYPE',{update_data,index})
            })
            .catch(errors=>{
                console.log(errors)
            })
    },
    ['DELETE_CUSTOMER_TYPE']({commit},id){
        axios.delete(`/customer/${id.id}`)
            .then(response=>{
                commit('SET_CUSTOMER_TYPE_DELETE',id.index)
            })
            .catch(errors=>{
                console.log(errors)
            })

    },
}

export  default {
    state,
    getters,
    mutations,
    actions
}