<template>
  <router-view></router-view>
</template>
<script>

import { mapState,mapGetters } from "vuex"
import { GETLASTID,ALL_USER_ROLE2 } from '@/store/action.type';
export default {
  name: 'app',
  mounted(){
  },
  methods:{

  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @font-face {
    font-family: "SolaimanLipi";
    src: url("assets/fonts/SolaimanLipi.ttf?v=1") format("opentype");
  }
  @font-face {
    font-family: "Hind Siliguri Light";
    src: url("assets/fonts/Hind_Siliguri_Light-Regular.ttf?v=1") format("opentype");
  }
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
  @import 'static/toast.min.css';
</style>
<style>
  .sidebar .nav-link.active {
    background: red ;
  }

</style>
