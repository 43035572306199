import Vue from 'vue'
import Router from 'vue-router'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/Charts')
const Widgets = () => import('@/views/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const Flags = () => import('@/views/icons/Flags')
const FontAwesome = () => import('@/views/icons/FontAwesome')
const SimpleLineIcons = () => import('@/views/icons/SimpleLineIcons')
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Messaging = () => import('@/views/messaging/Messaging')
const Register = () => import('@/views/pages/Register')
const ForgotPassword = () => import('@/views/pages/ForgotPassword')
const SmsCodeSubmit = () => import('@/views/pages/SmsCodeSubmit')
const ResetPassword = () => import('@/views/pages/ResetPassword')
const UserGroup = () => import('@/views/pages/UserGroup')
const UserList = () => import('@/views/pages/UserList')
const RolePermission = () => import('@/views/pages/UserRolePermission')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

//Demo Pages
const Project = () => import('@/views/project/Project')
const Contact = () => import('@/views/contact/Contact')
const ContactList = () => import('@/views/contact/ContactList')
const ContactDetails = () => import('@/views/contact/ContactDetails')
const Purpose = () => import('@/views/account/Purpose')
const Report = () => import('@/views/report/Report')
const CitizenReport = () => import('@/views/report/CitizenReport')
const BanksReport = () => import('@/views/report/BanksReport')
const WaterApplicationReport = () => import('@/views/report/WaterApplication')
const MarketApplicationReport = () => import('@/views/report/MarketApplication')
const MarketLeadger = () => import('@/views/report/MarketLeadger')
const MarketDemandCalculation = () => import('@/views/report/MarketDemandCalculation')
const MarketWiseShop = () => import('@/views/report/MarketWiseShop')
const MarketWiseDemandDetails = () => import('@/views/report/MarketWiseDemandDetails')
const MarketWiseDemandSummary = () => import('@/views/report/MarketWiseDemandSummary')
const MarketCollectionRateMonthly = () => import('@/views/report/MarketCollectionRateMonthly')
const MarketCollectionRateYearly = () => import('@/views/report/MarketCollectionRateYearly')
const WaterBillReport = () => import('@/views/report/WaterBill')
const waterApplicantDetails = () => import('@/views/report/waterApplicantDetails')
const waterAllGrahok = () => import('@/views/report/WaterAllGrahok')
const waterNewGrahok = () => import('@/views/report/WaterNewGrahok')
const shocholOcholGrahok = () => import('@/views/report/WaterShocholOcholGrahok')
const wardWiseGrahok = () => import('@/views/report/WaterWardWiseGrahok')
const waterBankWiseCollections= () => import('@/views/report/waterBankWiseCollections')
const waterAllDemandCollections= () => import('@/views/report/waterAllDemandCollections')
const waterConnectionFeeReport= () => import('@/views/report/waterConnectionFeeReport')
const waterDiaChangeReport= () => import('@/views/report/waterDiaChangeReport')
const waterGrahokLeadger= () => import('@/views/report/waterGrahokLeadger')

//Other Lease
const OtherLeaseApplicationReport = () => import('@/views/report/other-lease/OtherLeaseApplication')
const OtherLeaseLeadger = () => import('@/views/report/other-lease/OtherLeaseLeadger')
const OtherLeaseCollectionRate = () => import('@/views/report/other-lease/OtherLeaseCollectionRate')

const Income = () => import('@/views/money-in/Income')
const Invoice = () => import('@/views/money-in/Invoice')
const MarketInvoice = () => import('@/views/money-in/MarketInvoice')
const OtherLeaseInvoice = () => import('@/views/money-in/OtherLeaseInvoice')
const WaterInvoice = () => import('@/views/money-in/WaterInvoice')
const WaterOfcInvoice = () => import('@/views/money-in/WaterOfcInvoice')
const Water_bill = () => import('@/views/money-in/WaterBillreport')

const Bill = () => import('@/views/money-out/bill/index')
const HoldingList = () => import('@/views/holdings/holding')
const RoadAddress = () => import('@/views/holdings/roadAddress')
const Setting = () => import('@/views/setting/setting')
const Bank = () => import('@/views/bank/bankModal')
const Market = () => import('@/views/market/MarketView')
const CustomerList = () => import('@/views/holdings/customerType')
const otherLease = () => import('@/views/other-lease/otherLeaseView')

// Application-water
const water_new_connection = () => import ('@/views/application/water/new_connection');
const water_remove_connection = () => import ('@/views/application/water/remove_connection');
const water_update_connection = () => import ('@/views/application/water/update_connection');
const ApplyHolding = () => import ('@/views/application/others/find_user_for_holding');
const water_dia_change = () => import ('@/views/application/water/water_dia_change');

// Application-ofc-water
const water_ofc_new_connection = () => import ('@/views/application/water-ofc/new_connection');
const water_ofc_remove_connection = () => import ('@/views/application/water-ofc/remove_connection');
const water_ofc_update_connection = () => import ('@/views/application/water-ofc/update_connection');

// Application-market
const market_new_connection = () => import ('@/views/application/market/new_connection');
const market_ownership_change = () => import ('@/views/application/market/ownership_change');
const close_shop_open = () => import ('@/views/application/market/close_shop_open');

// Other Lease
const lease_new_connection = () => import ('@/views/application/other-lease/new_connection');
const lease_ownership_change = () => import ('@/views/application/other-lease/ownership_change');
const close_lease_open = () => import ('@/views/application/other-lease/close_lease_open');
const LeaseDemandCalculation = () => import('@/views/report/other-lease/LeaseDemandCalculation')

const holdingRegistration = () => import('@/views/holding-registration/HoldingView');

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [

    {
      path: '/',
      redirect: '/dashboard',
      name: 'হোম',
      component: DefaultContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'accounts/',
          name: 'Account',
          component: Contact,
          meta:{
            forAuth:true
          },
          children: [
          {
            path: 'list',
            name: 'Account List',
            component: ContactList,
          },
          {
            path: 'details/:id',
            name: 'ContactDetails',
            component: ContactDetails,
          },
        ]
        },
        //Application for water
        {
          path: '/application/market/new-connection',
          name: 'নতুন মার্কেট লিজ',
          component: market_new_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/market/ownership-change',
          name: 'দোকানের মালিকানা পরিবর্তন',
          component: market_ownership_change,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/market/close-shop-open',
          name: 'বন্ধ দোকান চালু',
          component: close_shop_open,
          meta:{
            forAuth:true
          }
        },
        //Application for other lease
        {
          path: '/application/other-lease/new-connection',
          name: 'নতুন লিজ',
          component: lease_new_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/other-lease/ownership-change',
          name: 'লিজ মালিকানা পরিবর্তন',
          component: lease_ownership_change,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/other-lease/close-lease-open',
          name: 'বন্ধ লিজ চালু',
          component: close_lease_open,
          meta:{
            forAuth:true
          }
        },
        //Application for water
        {
          path: '/application/water/new-connection',
          name: 'নতুন পানি সংযোগ',
          component: water_new_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/water-ofc/new-connection',
          name: 'নতুন পানি (অফিস) সংযোগ',
          component: water_ofc_new_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/water/dia-change',
          name: 'ডায়া পরিবর্তন',
          component: water_dia_change,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/water/remove-connection',
          name: 'বাতিল পানি সংযোগ',
          component: water_remove_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/water-ofc/remove-connection',
          name: 'বাতিল পানি (অফিস) সংযোগ',
          component: water_ofc_remove_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/water/update-connection',
          name: 'হালনাগাদ পানি সংযোগ',
          component: water_update_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/water-ofc/update-connection',
          name: 'হালনাগাদ পানি (অফিস) সংযোগ',
          component: water_ofc_update_connection,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/setting-list',
          name: 'Setting',
          component: Setting,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/application/holding',
          name: 'ApplyHolding',
          component: ApplyHolding,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/bank-list',
          name: 'Bank',
          component: Bank,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/market-list',
          name: 'Market',
          component: Market,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/other-lease',
          name: 'otherLease',
          component: otherLease,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/user-group',
          name: 'User Group',
          component: UserGroup,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/holding/pending-list',
          name: 'PendingHolding',
          component: HoldingList,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/holding/approved-list',
          name: 'ApprovedHolding',
          component: HoldingList,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/road-list',
          name: 'Road Address',
          component: RoadAddress,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/customer-list',
          name: 'Customer',
          component: CustomerList,
          meta:{
            forAuth:true
          }
        },
        {
          path: '/user-list',
          name: 'User List',
          component: UserList,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'item',
          name: 'item',
          component: Project,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'invoice',
          name: 'Invoice',
          component: Invoice,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'water-billing/list',
          name: 'Water billing',
          component: WaterInvoice,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'water-ofc-billing/list',
          name: 'Water Ofc billing',
          component: WaterOfcInvoice,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'market-billing/list',
          name: 'Market billing',
          component: MarketInvoice,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'other-lease-billing/list',
          name: 'Land Lease billing',
          component: OtherLeaseInvoice,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'water-billing/report',
          name: 'Report',
          component: Water_bill,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'holding-tax/list',
          name: 'Holding Tax',
          component: Invoice,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'bills',
          name: 'Bill',
          component: Bill,
          meta:{
            forAuth:true
          }
        },
        {
          path: "report",
          name: "রিপোর্ট",
          component: Report,
          meta:{
            forAuth:true
          }
        },
        {
          path: 'report/citizen',
          name: 'সিটিজেন রিপোর্ট',
          component: CitizenReport,
        },
        {
          path: 'report/bank-reports',
          name: 'ব্যাংক হিসাব বিবরণ',
          component: BanksReport,
        },
        {
          path: 'report/water-application',
          name: 'পানির আবেদন রিপোর্ট',
          component: WaterApplicationReport,
        },
        {
          path: 'report/market-application',
          name: 'মার্কেট আবেদন রিপোর্ট',
          component: MarketApplicationReport,
        },
        {
          path: 'report/other-lease-application',
          name: 'অন্যান্য লিজ রিপোর্ট',
          component: OtherLeaseApplicationReport,
        },
        {
          path: 'report/water-bill',
          name: 'পানির বিল রিপোর্ট',
          component: WaterBillReport,
        },
        {
          path: 'report/water-shochol-ochol',
          name: 'সচল, অচল ও বাতিলকৃত গ্রাহকের তালিকা',
          component: shocholOcholGrahok,
        },
        {
          path: 'report/water-all-grahok',
          name: 'গ্রাহকের তালিকা',
          component: waterAllGrahok,
        },
        {
          path: 'report/water-new-grahok',
          name: 'নতুন গ্রাহকের তালিকা',
          component: waterNewGrahok,
        },
        {
          path: 'report/water-ward-wise-grahok',
          name: 'ওয়ার্ড অনুসারে গ্রাহকের তালিকা',
          component: wardWiseGrahok,
        },
        {
          path: 'report/water-bank-wise-collections',
          name: 'ব্যাংক অনুসারে আদায়ের রিপোর্ট',
          component: waterBankWiseCollections,
        },
        {
          path: 'report/water-all-demanad-collection-reports',
          name: 'গ্রাহকের ডিমান্ড ও আদায় রিপোর্ট',
          component: waterAllDemandCollections,
        },
        {
          path: 'report/water-connection-fee-report',
          name: 'সংযোগ ফী আদায় রিপোর্ট',
          component: waterConnectionFeeReport,
        },
        {
          path: 'report/water-dia-change-report',
          name: 'গ্রাহকের ডায়া ও ধরন পরিবর্তনের রিপোর্ট',
          component: waterDiaChangeReport,
        },
        {
          path: 'report/water-grahok-leadger',
          name: 'গ্রাহক লেজার',
          component: waterGrahokLeadger,
        },
        {
          path: 'report/water-applicant-details',
          name: 'গ্রাহকের বিস্তারিত তথ্য',
          component: waterApplicantDetails,
        },
        {
          path: 'report/market-bill',
          name: 'মার্কেট বিল রিপোর্ট',
          component: WaterBillReport,
        },
        {
          path: 'report/other-lease-bill',
          name: 'অন্যান্য লিজ বিল রিপোর্ট',
          component: WaterBillReport,
        },
        {
          path: 'report/market-leadger',
          name: 'মার্কেট লেজার',
          component: MarketLeadger,
        },
        {
          path: 'report/other-lease-leadger',
          name: 'অন্যান্য লিজ লেজার',
          component: OtherLeaseLeadger,
        },
        {
          path: 'report/market-collection-rate-monthly',
          name: 'একনজরে বিভিন্ন মাসে আদায়ের হার',
          component: MarketCollectionRateMonthly,
        },
        {
          path: 'report/market-collection-rate-yearly',
          name: 'বাৎসরিক আদায়ের হার',
          component: MarketCollectionRateYearly,
        },
        {
          path: 'report/other-lease-collection-rate',
          name: 'বাৎসরিক আদায়ের হার',
          component: OtherLeaseCollectionRate,
        },
        {
          path: 'report/market-demand-calculation',
          name: 'মার্কেটের দাবি, আদায় ও আদায়ের হার',
          component: MarketDemandCalculation,
        },
        {
          path: 'report/other-lease/demand-calculation',
          name: 'লিজের দাবি, আদায় ও আদায়ের হার',
          component: LeaseDemandCalculation,
        },
        {
          path: 'report/market-wise-shop',
          name: 'মার্কেট অনুসারে দোকানের সংখ্যা',
          component: MarketWiseShop,
        },
        {
          path: 'report/market-wise-demand-details',
          name: 'বিভিন্ন মার্কেটে দাবির রিপোর্ট (বিস্তারিত)',
          component: MarketWiseDemandDetails,
        },
        {
          path: 'report/market-wise-demand-summary',
          name: 'বিভিন্ন মার্কেটে দাবির রিপোর্ট (সামারী)',
          component: MarketWiseDemandSummary,
        },
        {
          path: 'holding-registration',
          name: 'Holding',
          component: holdingRegistration,
        },
      ]
    },

    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta:{
        forVisitors:true
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta:{
        forVisitors:true
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/code-submit',
      name: 'CodeSubmit',
      component: SmsCodeSubmit
    },
    {
      path: '/reset-password/:email/:code',
      name: 'Reset Password',
      component: ResetPassword
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },

      ]
    },


  ]
})
