export const AUTH_USER = "AUTH_USER"
export const ALL_CONTACT = "ALL_CONTACT"
export const CONTACT_DETAILS = "CONTACT_DETAILS"
export const ALL_P_CONTACT = "ALL_P_CONTACT"
export const DELETE_CONTACT = "DELETE_CONTACT"
export const ADD_CONTACT = "ADD_CONTACT"
export const ADD_OFC_CONTACT = "ADD_OFC_CONTACT"
export const OPEN_CONTACT_MODAL = "OPEN_CONTACT_MODAL"
export const CLOSE_CONTACT_MODAL = "CLOSE_CONTACT_MODAL"
export const SEARCH_CONTACT = "SEARCH_CONTACT"

export const ALL_DISTRICT = "ALL_DISTRICT"

export const ALL_SUBDISTRICT = "ALL_SUBDISTRICT"

export const UPDATE_CONTACT = "UPDATE_CONTACT"

export const All_PROJECT_CATEGORY = "All_PROJECT_CATEGORY"
export const ADD_PROJECT_CATEGORY = "ADD_PROJECT_CATEGORY"
export const UPDATE_PROJECT_CATEGORY = "UPDATE_PROJECT_CATEGORY"
export const DELETE_PROJECT_CATEGORY = "DELETE_PROJECT_CATEGORY"
export const SEARCH_PROJECT_CATEGORY = "SEARCH_PROJECT_CATEGORY"
export const ALL_P_PROJECT_CATEGORY = "ALL_P_PROJECT_CATEGORY"


export const All_PROJECT = "All_PROJECT"
export const ADD_PROJECT = "ADD_PROJECT"
export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const DELETE_PROJECT = "DELETE_PROJECT"
export const SEARCH_PROJECT = "SEARCH_PROJECT"

export const ALL_PURPOSE = "ALL_PURPOSE"
export const ADD_PURPOSE = "ADD_PURPOSE"
export const UPDATE_PURPOSE = "UPDATE_PURPOSE"
export const DELETE_PURPOSE = "DELETE_PURPOSE"
export const ALL_P_PURPOSE = "ALL_P_PURPOSE"
export const SEARCH_PURPOSE = "SEARCH_PURPOSE"

export const ALL_PARENT_ACCOUNT = "ALL_PARENT_ACCOUNT"
export const ALL_ACCOUNT_TYPE = "ALL_ACCOUNT_TYPE"
export const ALL_ACCOUNT_TYPE2 = "ALL_ACCOUNT_TYPE2"

export const ALL_INCOME = "ALL_INCOME"
export const ADD_INCOME = "ADD_INCOME"
export const UPDATE_INCOME = "UPDATE_INCOME"
export const DELETE_INCOME = "DELETE_INCOME"
export const SEARCH_INCOME = "SEARCH_INCOME"

export const ALL_EXPENSE = "ALL_EXPENSE"
export const ADD_EXPENSE = "ADD_EXPENSE"
export const UPDATE_EXPENSE = "UPDATE_EXPENSE"
export const DELETE_EXPENSE = "DELETE_EXPENSE"
export const SEARCH_EXPENSE = "SEARCH_EXPENSE"

export const ALL_INVOICE = "ALL_INVOICE"
export const ADD_INVOICE = "ADD_INVOICE"
export const UPDATE_INVOICE = "UPDATE_INVOICE"
export const DUECHARGE_OFF_INVOICE = "DUECHARGE_OFF_INVOICE"
export const DELETE_INVOICE = "DELETE_INVOICE"
export const SEARCH_INVOICE = "SEARCH_INVOICE"
export const UNPAID_INVOICE = "UNPAID_INVOICE"

export const ALL_INSTALLMENT_INVOICE = "ALL_INSTALLMENT_INVOICE"



// payment received
export const ADD_PAYMENT_RECEIVE = "ADD_PAYMENT_RECEIVE";
export const UPDATE_PAYMENT_RECEIVE = "UPDATE_PAYMENT_RECEIVE";
export const DELETE_PAYMENT_RECEIVE = "DELETE_PAYMENT_RECEIVE";
export const ALL_PAYMENT_RECEIVE = "ALL_PAYMENT_RECEIVE";

// bills
export const BILLS = "BILLS";
export const ADD_BILL = "ADD_BILL";
export const DELETE_BILL = "DELETE_BILL";


// payment_made
export const PAYMENT_MADES = "PAYMENT_MADES";
export const ADD_PAYMENT_MADE = "ADD_PAYMENT_MADE";
export const DELETE_PAYMENT_MADE = "DELETE_PAYMENT_MADE";


// recuiting
export const ALL_VISA = "ALL_VISA"
export const ADD_VISA = "ADD_VISA"
export const CUSTOMER_VISA = "CUSTOMER_VISA"
export const DELETE_VISA = "DELETE_VISA"
export const GETLASTID = "GETLASTID"
export const REGISTER_USER = "REGISTER_USER"
export const LOGIN_USER = "LOGIN_USER"
export const FORGOT_USER = "FORGOT_USER"
export const CONFIRM_SMS_CODE = "CONFIRM_SMS_CODE"
export const RESET_USER = "RESET_USER"

//Messaging

export const FETCH_TOPICS = "FETCH_TOPICS"
export const FETCH_MESSAGES = "FETCH_MESSAGES"
export const ADD_MESSAGES = "ADD_MESSAGES"
export const FETCH_LOGED_IN_USER = "FETCH_LOGED_IN_USER"
export const FETCH_CATEGORY = "FETCH_CATEGORY"
export const DELETE_TOPICS = "DELETE_TOPICS"



export const ALL_USER_ROLE = "ALL_USER_ROLE"
export const ALL_USER_ROLE2 = "ALL_USER_ROLE2"
export const ADD_USER_ROLE = "ADD_USER_ROLE"
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE"
export const DELETE_USER_ROLE = "DELETE_USER_ROLE"

export const ALL_USER_ROLE_INFO = "ALL_USER_ROLE_INFO"
export const UPDATE_ASSIGN_USER_ROLE = "UPDATE_ASSIGN_USER_ROLE"

export const ROLE_PERMISSION = "ROLE_PERMISSION"
export const ROLE_PERMISSION_UPDATE = "ROLE_PERMISSION_UPDATE"

export const CURRENT_LEADGER = "CURRENT_LEADGER"
export const SEARCH_LEADGER = "SEARCH_LEADGER"

export const STATISTICS = "STATISTICS"
export const REPORT = "REPORT"

export const SEARCH_WORKERS="SEARCH_WORKERS"

export const ALL_APPLICATION = "ALL_APPLICATION"
export const ALL_CLOSE_APPLICATION = "ALL_CLOSE_APPLICATION"
export const ADD_APPLICATION = "ADD_APPLICATION"
export const UPDATE_APPLICATION = "UPDATE_APPLICATION"
export const DELETE_APPLICATION = "DELETE_APPLICATION"
export const SEARCH_APPLICATION = "SEARCH_APPLICATION"
export const UPDATE_MARKET_APPLICATION = "UPDATE_MARKET_APPLICATION"
export const UPDATE_CLOSE_MARKET_APPLICATION = "UPDATE_CLOSE_MARKET_APPLICATION"
export const MALIKANA_PORIBORTON = "MALIKANA_PORIBORTON"
export const MALIKANA_PORIBORTON_LEASE = "MALIKANA_PORIBORTON_LEASE"
