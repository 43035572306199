import axios from 'axios';
import iziToast from "izitoast";

const state ={
    settingLists:[],
    bankLists: {},
    marketLists: {},
    villageLists:{},
    leaseLists: {},
    holdingRegLists:{},
    modals:false
}

const getters={
    settingLists(state){
        return state.settingLists
    },
    bankLists(state){
        return state.bankLists
    },
    marketLists(state){
        return state.marketLists
    },
    leaseLists(state){
        return state.leaseLists
    },
    holdingRegLists(state){
        return state.holdingRegLists
    },
    villageLists(state){
        return state.villageLists
    },
    modals(state){
        return state.modals
    }

}

const  mutations ={
    ['AFTER_ADD_SETTING'](state,data){
        state.settingLists.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Setting Added Successsfully"})
    },
    ['AFTER_UPDATE_SETTING'](state,payload){
        state.settingLists.splice(payload.index,0,payload.update_data)
        state.settingLists.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Setting Updated Successsfully"})
    },
    ['AFTER_UPDATE_BANK'](state,payload){
        state.bankLists.data.splice(payload.index,0,payload.update_data)
        state.bankLists.data.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Bank Updated Successsfully"})
    },
    ['AFTER_UPDATE_MARKET'](state,payload){
        state.marketLists.data.splice(payload.index,0,payload.update_data)
        state.marketLists.data.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Market Updated Successsfully"})
    },
    ['AFTER_UPDATE_HOLDING_REG'](state,payload){
        state.holdingRegLists.data.splice(payload.index,0,payload.update_data)
        state.holdingRegLists.data.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Holding Updated Successsfully"})
    },
    ['AFTER_UPDATE_LEASE'](state,payload){
        state.leaseLists.data.splice(payload.index,0,payload.update_data)
        state.leaseLists.data.splice(payload.index+1,1)
        state.modal = false
        iziToast.success({position:'topRight',title:'Ok',message:"Lease Updated Successsfully"})
    },
    ['AFTER_DELETE_SETTING'](state,payload){
        state.settingLists.splice(payload.index,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Setting Deleted Successsfully"})
    },
    ['AFTER_DELETE_BANK'](state,payload){
        state.bankLists.data.splice(payload,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Bank Deleted Successsfully"})
    },
    ['AFTER_DELETE_MARKET'](state,payload){
        state.marketLists.data.splice(payload,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Market Deleted Successsfully"})
    },
    ['AFTER_DELETE_HOLDING_REG'](state,payload){
        state.holdingRegLists.data.splice(payload,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Holding Deleted Successsfully"})
    },
    ['AFTER_DELETE_LEASE'](state,payload){
        state.leaseLists.data.splice(payload,1)
        iziToast.success({position:'topRight',title:'Ok',message:"Lease Deleted Successsfully"})
    },
    ['SET_SETTING_LIST'](state,payload){
        state.settingLists = payload
    },
    ['SET_BANK_LIST'](state,payload){
        state.bankLists = payload
    },
    ['SET_MARKET_LIST'](state,payload){
        state.marketLists = payload
    },
    ['SET_HOLDING_REG_LIST'](state,payload){
        state.holdingRegLists = payload
    },
    ['SET_VILLAGES_LIST'](state,payload){
        state.villageLists = payload
    },
    ['SET_LEASE_LIST'](state,payload){
        state.leaseLists = payload
    },
    ['AFTER_ADD_BANK'](state,data){
        state.bankLists.data.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Bank Added Successsfully"})
    },
    ['AFTER_ADD_MARKET'](state,data){
        state.marketLists.data.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Market Added Successsfully"})
    },
    ['AFTER_HOLDING_REG'](state,data){
        state.holdingRegLists.data.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Holding Added Successsfully"})
    },

    ['AFTER_ADD_LEASE'](state,data){
        state.leaseLists.data.unshift(data)
        iziToast.success({position:'topRight',title:'Ok',message:"Lease Added Successsfully"})
    },
}

const actions ={
    ['ADD_SETTING']({commit},data){
        axios.post('/setting',data)
            .then(response=>{
                commit('AFTER_ADD_SETTING',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['ADD_BANK']({commit},data){
        return new Promise((resolve,reject) => {
            axios.post('/bank',data)
                .then(response=>{
                    commit('AFTER_ADD_BANK',response.data)
                    resolve(response.data)
                })
                .catch(error=>{
                    reject(error)
                    console.log(error)
                })
        })
    },
    ['UPDATE_SETTING']({commit},data){
        axios.put(`/setting/edit/${data.editForm.id}`,data.editForm)
            .then(response=>{
                var update_data = response.data
                var index = data.index
                commit('AFTER_UPDATE_SETTING',{update_data,index})
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['SETTING_LIST']({commit}){
        axios.get('/setting/lists')
            .then(response=>{
                commit('SET_SETTING_LIST',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['DELETE_SETTING']({commit},data){
        axios.delete(`/setting/${data.id}`)
            .then(response=>{
                var index = data.index
                commit('AFTER_DELETE_SETTING',index)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['BANK_LIST']({commit},data){
        axios.get("/bank/lists?page="+data.page)
            .then(response=>{
                commit('SET_BANK_LIST',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['UPDATE_BANK']({commit},data){
        return new Promise((resolve,reject)=>{
            axios.put(`/bank/edit/${data.editForm.id}`,data.editForm)
                .then(response=>{
                    var update_data = response.data
                    var index = data.index
                    commit('AFTER_UPDATE_BANK',{update_data,index})
                    resolve(response.data)
                })
                .catch(error=>{
                    console.log(error)
                    reject(error)
                })
        })

    },
    ['DELETE_BANK']({commit},data){
        axios.delete(`/bank/${data.id}`)
            .then(response=>{
                var index = data.index
                commit('AFTER_DELETE_BANK',index)
            })
            .catch(error=>{
                console.log(error)
            })
    },

    ['MARKET_LIST']({commit},data){
        axios.get("/market/lists?page="+data.page)
            .then(response=>{
                commit('SET_MARKET_LIST',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['ADD_MARKET']({commit},data){
        return new Promise((resolve,reject) => {
            axios.post('/market',data)
                .then(response=>{
                    commit('AFTER_ADD_MARKET',response.data)
                    resolve(response.data)
                })
                .catch(error=>{
                    reject(error)
                    console.log(error)
                })
        })
    },
    ['UPDATE_MARKET']({commit},data){
        return new Promise((resolve,reject)=>{
            axios.put(`/market/edit/${data.editForm.id}`,data.editForm)
                .then(response=>{
                    var update_data = response.data
                    var index = data.index
                    commit('AFTER_UPDATE_MARKET',{update_data,index})
                    resolve(response.data)
                })
                .catch(error=>{
                    console.log(error)
                    reject(error)
                })
        })

    },
    ['DELETE_MARKET']({commit},data){
        axios.delete(`/market/${data.id}`)
            .then(response=>{
                var index = data.index
                commit('AFTER_DELETE_MARKET',index)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['ADD_HOLDING_REG']({commit},data){
        return new Promise((resolve,reject) => {
            axios.post('/holding-registration',data)
            .then(response=>{
                if(response.data.exists=='exists'){
                    iziToast.error({position:'topRight',title:'Error',message:"Already Exsists !"})
                }else{
                    commit('AFTER_HOLDING_REG',response.data)
                    resolve(response.data)
                }
            })
            .catch(error=>{
                reject(error)
                console.log(error)
            })
        })
    },
    ['UPDATE_HOLDING_REG_MARKET']({commit},data){
        return new Promise((resolve,reject)=>{
            axios.put(`/holding-registration/edit/${data.editForm.id}`,data.editForm)
                .then(response=>{
                    var update_data = response.data
                    var index = data.index
                    commit('AFTER_UPDATE_HOLDING_REG',{update_data,index})
                    resolve(response.data)
                })
                .catch(error=>{
                    console.log(error)
                    reject(error)
                })
        })

    },
    ['DELETE_HOLDING_REG']({commit},data){
        axios.delete(`/holding-registration/${data.id}`)
            .then(response=>{
                var index = data.index
                commit('AFTER_DELETE_HOLDING_REG',index)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['HOLDING_REG_LIST']({commit},data){
        axios.get("/holding-registration/lists?page="+data.page)
            .then(response=>{
                commit('SET_HOLDING_REG_LIST',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },

    ['HOLDING_VILLAGE_LIST']({commit}){
        axios.get("/villages/lists")
            .then(response=>{
                //console.log(response.data)
                commit('SET_VILLAGES_LIST',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },

    ['LEASE_LIST']({commit},data){
        axios.get("/lease/lists?page="+data.page)
            .then(response=>{
                commit('SET_LEASE_LIST',response.data)
            })
            .catch(error=>{
                console.log(error)
            })
    },
    ['ADD_LEASE']({commit},data){
        return new Promise((resolve,reject) => {
            axios.post('/lease',data)
                .then(response=>{
                    commit('AFTER_ADD_LEASE',response.data)
                    resolve(response.data)
                })
                .catch(error=>{
                    reject(error)
                    console.log(error)
                })
        })
    },
    ['UPDATE_LEASE']({commit},data){
        return new Promise((resolve,reject)=>{
            axios.put(`/lease/edit/${data.editForm.id}`,data.editForm)
                .then(response=>{
                    var update_data = response.data
                    var index = data.index
                    commit('AFTER_UPDATE_LEASE',{update_data,index})
                    resolve(response.data)
                })
                .catch(error=>{
                    console.log(error)
                    reject(error)
                })
        })

    },
    ['DELETE_LEASE']({commit},data){
        axios.delete(`/lease/${data.id}`)
            .then(response=>{
                var index = data.index
                commit('AFTER_DELETE_LEASE',index)
            })
            .catch(error=>{
                console.log(error)
            })
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}