export const SET_AUTH_USER = "SET_AUTH_USER"
export const SET_CONTACT = "SET_CONTACT"
export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS"
export const SET_P_CONTACT = "SET_P_CONTACT"
export const AFTER_DELETE_CONTACT = "AFTER_DELETE_CONTACT"
export const AFTER_ADD_CONTACT = "AFTER_ADD_CONTACT"
export const AFTER_ADD_OFC_CONTACT = "AFTER_ADD_OFC_CONTACT"
export const SET_DISTRICT = "SET_DISTRICT"
export const SET_SUBDISTRICT = "SET_SUBDISTRICT"
export const DISTRICT_LOADER = "DISTRICT_LOADER"
export const SUBDISTRICT_LOADER = "SUBDISTRICT_LOADER"
export const AFTER_UPDATE_CONTACT = "AFTER_UPDATE_CONTACT"
export const SET_CONTACT_MODAL = "SET_CONTACT_MODAL"

export const SET_PROJECT_CATEGORY = "SET_PROJECT_CATEGORY"
export const AFTER_ADD_PROJECT_CATEGORY = "AFTER_ADD_PROJECT_CATEGORY"
export const AFTER_UPDATE_PROJECT_CATEGORY = "AFTER_UPDATE_PROJECT_CATEGORY"
export const AFTER_DELETE_PROJECT_CATEGORY = "AFTER_DELETE_PROJECT_CATEGORY"
export const SET_P_PROJECT_CATEGORY = "SET_P_PROJECT_CATEGORY"


export const SET_PROJECT = "SET_PROJECT"
export const AFTER_ADD_PROJECT = "AFTER_ADD_PROJECT"
export const AFTER_UPDATE_PROJECT = "AFTER_UPDATE_PROJECT"
export const AFTER_DELETE_PROJECT = "AFTER_DELETE_PROJECT"

export const SET_PURPOSE = "SET_PURPOSE"
export const AFTER_ADD_PURPOSE = "AFTER_ADD_PURPOSE"
export const AFTER_UPDATE_PURPOSE = "AFTER_UPDATE_PURPOSE"
export const AFTER_DELETE_PURPOSE = "AFTER_DELETE_PURPOSE"
export const SET_P_PURPOSE = "SET_P_PURPOSE"

export const SET_PARENT_ACCOUNT = "SET_PARENT_ACCOUNT"
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE"
export const SET_ACCOUNT_TYPE2 = "SET_ACCOUNT_TYPE2"

export const SET_INCOME = "SET_INCOME"
export const AFTER_ADD_INCOME = "AFTER_ADD_INCOME"
export const AFTER_UPDATE_INCOME = "AFTER_UPDATE_INCOME"
export const AFTER_DELETE_INCOME = "AFTER_DELETE_INCOME"

export const SET_EXPENSE = "SET_EXPENSE"
export const AFTER_ADD_EXPENSE = "AFTER_ADD_EXPENSE"
export const AFTER_UPDATE_EXPENSE = "AFTER_UPDATE_EXPENSE"
export const AFTER_DELETE_EXPENSE = "AFTER_DELETE_EXPENSE"

export const SET_INVOICE = "SET_INVOICE"
export const AFTER_ADD_INVOICE = "AFTER_ADD_INVOICE"
export const AFTER_UPDATE_INVOICE = "AFTER_UPDATE_INVOICE"
export const AFTER_DUECHARGE_OFF_INVOICE = "AFTER_DUECHARGE_OFF_INVOICE"
export const AFTER_DELETE_INVOICE = "AFTER_DELETE_INVOICE"
export const SET_UNPAID_INVOICE = "SET_UNPAID_INVOICE"

// payment received
export const SET_ADD_PAYMENT = "SET_ADD_PAYMENT";
export const SET_ALL_PAYMENT_RECEIVE = "SET_ALL_PAYMENT_RECEIVE";
export const SET_ADD_PAYMENT_RECEIVE = "SET_ADD_PAYMENT_RECEIVE";
export const AFTER_UPDATE_PAYMENT = "AFTER_UPDATE_PAYMENT";
export const AFTER_DELETE_PAYMENT = "AFTER_DELETE_PAYMENT";

// bills
export const SET_BILLS = "SET_BILLS";
export const SET_ADD_BILL = "SET_ADD_BILL";
export const SET_DELETE_BILL = "SET_DELETE_BILL";

// payment made
export const SET_PAYMENT_MADES = "SET_PAYMENT_MADES";
export const SET_ADD_PAYMENT_MADE = "SET_ADD_PAYMENT_MADE";
export const SET_DELETE_PAYMENT_MADE = "SET_DELETE_PAYMENT_MADE";

//recuiting
export const SET_ALL_VISA = "SET_ALL_VISA"
export const SET_ADD_VISA = "SET_ADD_VISA"
export const SET_DELETE_VISA = "SET_DELETE_VISA"
export const SET_GETLASTID = "SET_GETLASTID"
export const SET_CUSTOMER_VISA = "SET_CUSTOMER_VISA"


export const SET_REGISTER_NOTIFIACTION = "SET_REGISTER_NOTIFIACTION"
export const SET_LOGIN_NOTIFIACTION = "SET_LOGIN_NOTIFIACTION"
export const SET_USER_INFO = "SET_USER_INFO"
export const SET_FORGOT_PASSWORD_NOTIFIACTION = "SET_FORGOT_PASSWORD_NOTIFIACTION"
export const SET_RESET_PASSWORD_NOTIFIACTION = "SET_RESET_PASSWORD_NOTIFIACTION"

export const SET_USER_ROLE = "SET_USER_ROLE"
export const SET_USER_ROLE2 = "SET_USER_ROLE2"
export const AFTER_ADD_USER_ROLE = "AFTER_ADD_USER_ROLE"
export const AFTER_UPDATE_USER_ROLE = "AFTER_UPDATE_USER_ROLE"
export const AFTER_DELETE_USER_ROLE = "AFTER_DELETE_USER_ROLE"

export const SET_USER_ROLE_INFO = "SET_USER_ROLE_INFO"
export const AFTER_UPDATE_ASSIGN_USER_ROLE = "AFTER_UPDATE_ASSIGN_USER_ROLE"


export const SET_ROLE_PERMISSION = "SET_ROLE_PERMISSION"
export const AFTER_UPDATE_ROLE_PERMISSION = "AFTER_UPDATE_ROLE_PERMISSION"

export const SET_CURRENT_LEADGER = "SET_CURRENT_LEADGER"

export const SET_STATISTICS = "SET_STATISTICS"
export const SET_REPORT = "SET_REPORT"


export const SET_SEARCH_WORKERS="SET_SEARCH_WORKERS"

export const SET_APPLICATION = "SET_APPLICATION"
export const AFTER_ADD_APPLICATION = "AFTER_ADD_APPLICATION"
export const AFTER_UPDATE_APPLICATION = "AFTER_UPDATE_APPLICATION"
export const AFTER_DELETE_APPLICATION = "AFTER_DELETE_APPLICATION"
export const AFTER_MALIKANA_PORIBORTON = "AFTER_MALIKANA_PORIBORTON"
export const AFTER_MALIKANA_PORIBORTON_LEASE = "AFTER_MALIKANA_PORIBORTON_LEASE"





